<template>
    <b-sidebar
            no-close-on-backdrop
            shadow
            no-header
            right
            backdrop
            bg-variant="white"
            sidebar-class="sidebar-lg"
            id="document-new-record-sidebar"
            :backdrop-variant="'dark'"
            :visible="isDocumentNewSidebarActive">
        <template #default="{ hide }">

            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Döküman Ekle</h5>
            </div>

            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" id="documentForm">

                    <validation-provider #default="validationContext" name="Döküman">
                        <b-form-group label="Dosya" description="Birden fazla dosya seçimi yapabilirsiniz...">
                            <b-form-file id="customerComplaintFiles" name="customerComplaintFiles" placeholder="Dosya seçimi yapınız..." drop-placeholder="Dosyalarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                        </b-form-group>
                    </validation-provider>

                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit">Kaydet</b-button>
                        <b-button type="button" variant="outline-secondary" @click="$emit('update:is-document-new-sidebar-active', false)">Vazgeç</b-button>
                    </div>

                </b-form>
            </validation-observer>

            <Overlay :busy="busy"></Overlay>

        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";

export default {
    components: {
        BFormFile,
        BFormTextarea,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,

        vSelect,
        Overlay,

        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: 'isDocumentNewSidebarActive',
        event: 'update:is-document-new-sidebar-active',
    },
    props: {
        isDocumentNewSidebarActive: {
            type: Boolean,
            required: true,
        },
        recordDataId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            required,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()
        const busy = ref(false)

        const onSubmit = () => {
            const documents = document.getElementById('customerComplaintFiles').files

            const formData = new FormData();
            for (let i = 0; i < documents.length; i++) {
                let file = documents[i];
                formData.append('documents[' + i + ']', file);
            }

            busy.value = true
            store.dispatch('store/addDocument', {
                customerComplaintId: router.currentRoute.params.id,
                dataInfo: formData
            }).then(response => {
                toastMessage(toast, 'success', response.data.message)
                emit('update:is-document-new-sidebar-active', false)
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
            }).finally(message => {
                busy.value = false
            })
        }

        const resetRecordData = () => {
            document.getElementById("documentForm").reset();
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetRecordData)

        watch(() => props.isDocumentNewSidebarActive, (dataValue) => {
            if (dataValue) {
                resetForm()
            } else {
                resetForm()
            }
        })

        return {
            busy,
            refFormObserver,

            getValidationState,
            resetForm,
            onSubmit,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#document-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
