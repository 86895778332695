import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/customercomplaints', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaints/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaints/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/customercomplaints', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/customercomplaints', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercomplaints/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaints/cancel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActionList(ctx, {customerComplaintId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintactions/${customerComplaintId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewAction(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintactions/${customerComplaintId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAction(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintactions/${customerComplaintId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAction(ctx, {customerComplaintId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintactions/${customerComplaintId}/v2`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editAction(ctx, {customerComplaintId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/customercomplaintactions/${customerComplaintId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeAction(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercomplaintactions/${customerComplaintId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelAction(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintactions/cancel/${customerComplaintId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDocumentList(ctx, {customerComplaintId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintdocuments/${customerComplaintId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewDocument(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintdocuments/${customerComplaintId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocument(ctx, {customerComplaintId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintdocuments/${customerComplaintId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeDocument(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercomplaintdocuments/${customerComplaintId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelDocument(ctx, {customerComplaintId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintdocuments/cancel/${customerComplaintId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActionDocumentList(ctx, {customerComplaintActionId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintactiondocuments/${customerComplaintActionId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeActionDocument(ctx, {customerComplaintActionId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercomplaintactiondocuments/${customerComplaintActionId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelActionDocument(ctx, {customerComplaintActionId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintactiondocuments/cancel/${customerComplaintActionId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
