<template>
    <b-card>
        <b-tabs pills>
            <b-tab active>
                <template #title>
                    <feather-icon icon="ApertureIcon"/>
                    <span>Müşteri Şikayeti Bilgileri</span>
                </template>
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <b-row>
                            <b-col cols="12" md="4" lg="4">
                                <b-row>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Tarih" rules="required">
                                            <b-form-group label="Tarih" :state="getValidationState(validationContext)">
                                                <flat-pickr v-model="dataInfo.docDate" class="form-control" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Müşteri" rules="required">
                                            <b-form-group label="Müşteri" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.customerId" :options="customerOptions" :reduce="val => val.value" :clearable="true" v-on:input="getCustomerContacts"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="İlgili Kişi" rules="required">
                                            <b-form-group label="İlgili Kişi" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.customerContactId" :options="customerContactOptions" :reduce="val => val.value" :clearable="true"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Konu" rules="required">
                                            <b-form-group label="Konu" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.complaintSubjectId" :options="complaintSubjectOptions" :reduce="val => val.value" :clearable="true"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Satış Döküman Numarası">
                                            <b-form-group label="Satış Döküman Numarası">
                                                <b-form-input trim placeholder="Satış Döküman Numarası" v-model="dataInfo.salesDocNumber" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Ürün Kodu">
                                            <b-form-group label="Ürün Kodu">
                                                <b-form-input trim placeholder="Ürün Kodu" v-model="dataInfo.productCode" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Lot Numarası">
                                            <b-form-group label="Lot Numarası">
                                                <b-form-input trim placeholder="Lot Numarası" v-model="dataInfo.lotnNumber" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Miktar">
                                            <b-form-group label="Miktar">
                                                <b-form-input trim placeholder="Miktar" v-model="dataInfo.amount" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                </b-row>
                            </b-col>
                            <b-col cols="12" md="8" lg="8">
                                <b-col cols="12" md="12" lg="12">
                                    <validation-provider #default="validationContext" name="Açıklama" rules="required">
                                        <b-form-group label="Açıklama">
                                            <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="25"/>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-col>
                        </b-row>
                        <action-buttons :back-route="'customer-complaint-list'"/>
                    </b-form>
                </validation-observer>
            </b-tab>
            <ActionList></ActionList>
            <DocumentList></DocumentList>
        </b-tabs>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BTable, BFormTextarea, BButton, BTooltip
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils"
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/customer_complaint/store"
import definitionModule from "@/views/system/definition/store"
import customerModule from "@/views/definition/customer/store"
import subjectModule from "@/views/definition/complaint_subject/store"
import flatPickr from 'vue-flatpickr-component'
import {yesNoOptions} from "@core/utils/filter"
import ActionList from "@/views/customer_complaint/action/List.vue"
import DocumentList from "@/views/customer_complaint/document/List.vue";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BTabs,
        BTab,
        BTable,
        BFormTextarea,
        BButton,
        BTooltip,
        flatPickr,

        ActionButtons,
        ActionList,
        DocumentList,
        Overlay,
        vSelect,

        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        const STORE_DEFINITION_MODULE_NAME = 'store-definition'
        const STORE_CUSTOMER_MODULE_NAME = 'store-customer'
        const STORE_SUBJECT_MODULE_NAME = 'store-subject'

        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, storeModule)
            store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
            store.registerModule(STORE_CUSTOMER_MODULE_NAME, customerModule)
            store.registerModule(STORE_SUBJECT_MODULE_NAME, subjectModule)
        }
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) {
                store.unregisterModule(STORE_MODULE_NAME)
                store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
                store.unregisterModule(STORE_CUSTOMER_MODULE_NAME)
                store.unregisterModule(STORE_SUBJECT_MODULE_NAME)
            }
        })

        const toast = useToast()
        const busy = ref(false)
        const dataInfo = ref({
            id: 0,
            docDate: null,
            customerId: null,
            customerContactId: null,
            complaintSubjectId: null,
            turnTimeValue: 0,
            timeValueUnitId: null,
            salesDocNumber: '',
            productCode: '',
            lotnNumber: '',
            amount: '',
            description: '',
            userId: 0,
            isCancelled: false,
        })

        const customerOptions = ref([])
        const customerContactOptions = ref([])
        const complaintSubjectOptions = ref([])

        const onSubmit = () => {
            busy.value = true
            store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                toastMessage(toast, 'success', response.data.message)
                router.push({name: 'customer-complaint-list'})
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
            }).finally(message => {
                busy.value = false
            })
        }

        const getCustomerContacts = (val) => {
            customerContactOptions.value = []
            if (val != null) {
                busy.value = true
                store.dispatch('store-customer/fetchContactList', {customerId: val}).then(response => {
                    response.data.data.forEach((value, index) => {
                        customerContactOptions.value.push({label: value.name + ' ' + value.lastName, value: value.id})
                    });
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                }).finally(message => {
                    busy.value = false
                })
            } else {
                dataInfo.value.customerContactId = null
            }
        }

        busy.value = true
        store.dispatch('store-customer/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.isActive) {
                    customerOptions.value.push({label: value.name, value: value.id})
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        busy.value = true
        store.dispatch('store-subject/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.isActive) {
                    complaintSubjectOptions.value.push({label: value.name, value: value.id})
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        if (router.currentRoute.params.id > 0) {
            busy.value = true
            store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
                dataInfo.value = response.data.data
                if (dataInfo.value.customerId > 0) {
                    getCustomerContacts(dataInfo.value.customerId)
                }
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
                if (error.response.status === 403) {
                    router.push({name: 'customer-complaint-list'})
                }
            }).finally(message => {
                busy.value = false
            })
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        return {
            busy,
            dataInfo,
            refFormObserver,

            customerOptions,
            customerContactOptions,
            complaintSubjectOptions,

            yesNoOptions,

            onSubmit,
            getValidationState,
            resetForm,

            getCustomerContacts,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

.quill-editor {
  height: 600px;
}

</style>