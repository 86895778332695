<template>
    <b-modal
            no-close-on-backdrop
            centered
            hide-header-close
            hide-footer
            ok-variant="dark"
            title="Kayıt Bilgileri"
            modal-class="modal-dark"
            v-model="isPreviewModal"
            size="lg"
            @close.prevent="$emit('update:is-preview-modal', false)">

        <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom">
                <th class="pb-50"><span class="font-weight-bold">Tarih</span></th>
                <td class="pb-50">
                    <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
                </td>
            </tr>
            <tr class="border-bottom">
                <th class="py-50"><span class="font-weight-bold">Kullanıcı</span></th>
                <td class="py-50">{{ previewData.userName }}</td>
            </tr>
            <tr class="border-bottom">
                <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
                <td class="py-50"><span v-html="previewData.description"></span></td>
            </tr>
            <tr class="border-bottom">
                <th class="py-50"><span class="font-weight-bold">Durum</span></th>
                <td class="py-50"><span v-html="previewData.customerComplaintStatusName"></span></td>
            </tr>
            <tr>
                <th class="pt-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
                <td class="pt-50">
                    <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
                </td>
            </tr>
        </table>

        <div class="my-4"></div>

        <h5>Döküman Bilgileri</h5>

        <b-table
                ref="refListTable"
                primary-key="id"
                empty-text="Kayıt bulunamadı"
                :class="`position-relative`"
                :items="previewDocumentData"
                :fields="previewDocumentColumns"
                responsive striped bordered hover show-empty>

            <template #cell(id)="data">
                <b-link class="font-weight-bold">#{{ data.value }}</b-link>
            </template>

            <template #cell(createdOn)="data">
                <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(isCancelled)="data">
                <boolean-column-table :data="data.value"></boolean-column-table>
            </template>

            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item @click="cancelDocument(data.item)">
                            <feather-icon icon="XIcon"/>
                            <span class="align-middle ml-50">İptal</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeDocument(data.item)">
                            <feather-icon icon="TrashIcon"/>
                            <span class="align-middle ml-50">Çıkar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-link class="font-weight-bold" :href="getApiFile(data.item.filePath)" target="_blank">
                        <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" class="mx-1"/>
                    </b-link>
                    <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
                </div>
            </template>

        </b-table>

        <div class="border-bottom my-1"></div>

        <b-row class="d-flex justify-content-end align-items-center">
            <b-button
                    class="mr-1"
                    variant="outline-secondary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="$emit('update:is-preview-modal', false)">
                <feather-icon icon="XCircleIcon" size="16"/>
                <span class="align-middle" role="button"> Kapat</span>
            </b-button>
        </b-row>

        <Overlay :busy="busy"></Overlay>
    </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BBadge, BButton, BDropdown, BDropdownItem, BLink, BModal, BRow, BTable, BTooltip} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";
import router from "@/router";
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue";
import Vue from "vue";
import {getApiFile} from "@core/utils/filter"

export default {
    components: {
        BLink, BTooltip, BTable, BooleanColumnTable, BDropdown, BDropdownItem,
        DateTimeColumnTable,
        ActiveColumnTable,
        Overlay,

        BRow,
        BButton,
        BModal,
        BBadge,
    },
    model: [
        {
            prop: 'isPreviewModal',
            event: 'update:is-preview-modal',
        },
        {
            prop: 'dataRecord',
            event: 'update:data-record',
        }
    ],
    props: {
        isPreviewModal: {
            type: Boolean,
            required: true,
        },
        dataRecord: {
            type: Object,
            required: true,
        },
    },
    setup(props, {emit}) {
        const toast = useToast()
        const busy = ref(false)
        const previewData = ref({});
        const previewDocumentData = ref([]);

        watch(() => props.dataRecord, (dataValue) => {
            if (dataValue.id !== undefined && props.isPreviewModal) {

                busy.value = true
                store.dispatch('store/previewAction', {
                    customerComplaintId: router.currentRoute.params.id,
                    id: dataValue.id
                }).then(response => {
                    previewData.value = response.data.data
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                    if (error.response.status === 403) {
                        emit('update:is-preview-modal', false)
                        emit('update:data-record', {})
                    }
                }).finally(message => {
                    busy.value = false
                })

                busy.value = true
                store.dispatch('store/fetchActionDocumentList', {
                    customerComplaintActionId: dataValue.id
                }).then(response => {
                    previewDocumentData.value = response.data.data
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                    if (error.response.status === 403) {
                        //error
                    }
                }).finally(message => {
                    busy.value = false
                })
            }
        })

        const previewDocumentColumns = [
            {key: 'name', label: 'İsim', sortable: true},
            {key: 'fileType', label: 'Tür', sortable: true},
            {key: 'fileSize', label: 'Boyut', sortable: true},
            {key: 'createdOn', label: 'Tarih', sortable: true},
            {key: 'isCancelled', label: 'İptal', sortable: true},
            {key: 'actions', label: 'İşlemler', thStyle: {width: "2%"}},
        ]

        const removeDocument = (dataItem) => {
            Vue.swal({
                title: 'Onaylıyor musunuz?',
                text: 'Kayıt çıkarılacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Çıkar',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('store/removeActionDocument', {
                        customerComplaintActionId: props.dataRecord.id,
                        id: dataItem.id
                    }).then(response => {
                        if (response.data.success) {
                            emit('update:is-preview-modal', false)
                            emit('update:data-record', {})
                            Vue.swal({
                                icon: 'success',
                                title: 'Başarılı!',
                                text: 'Kayıt çıkarıldı.',
                                confirmButtonText: 'Tamam',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                            })
                        }
                    }).catch(error => {
                        Vue.swal({
                            icon: 'error',
                            title: 'Hata!',
                            text: error.response.data.message,
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    })
                }
            })
        }

        const cancelDocument = (dataItem) => {
            Vue.swal({
                title: 'Onaylıyor musunuz?',
                text: 'Kayıt iptal edilecektir!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, İptal',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('store/cancelActionDocument', {
                        customerComplaintActionId: props.dataRecord.id,
                        id: dataItem.id
                    }).then(response => {
                        if (response.data.success) {
                            emit('update:is-preview-modal', false)
                            emit('update:data-record', {})

                            Vue.swal({
                                icon: 'success',
                                title: 'Başarılı!',
                                text: 'Kayıt iptal edildi.',
                                confirmButtonText: 'Tamam',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                            })
                        }
                    }).catch(error => {
                        Vue.swal({
                            icon: 'error',
                            title: 'Hata!',
                            text: error.response.data.message,
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    })
                }
            })
        }

        return {
            busy,
            previewData,
            previewDocumentData,
            previewDocumentColumns,

            recordStatusFormat,
            recordStatusVariant,

            removeDocument,
            cancelDocument,

            getApiFile,
        }
    },
}
</script>
